import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormPhone = lazy(
  () => import("./form-phone-BgN3hVY_.js").then((module) => ({
    default: module.FormPhone
  }))
);
function FormPhone({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormPhone, { ...props });
}
const useFormPhoneContent = () => {
  return useMemo(
    () => ({
      formPhone: {
        component: FormPhone
      }
    }),
    []
  );
};
export {
  LazyFormPhone as FormPhone,
  useFormPhoneContent
};
